




























































import Component from 'vue-class-component';
import Vue from 'vue';
import RsOnboardingHeader from '@/modules/onboarding/components/main/header.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {OnboardingRouter} from '@/modules/onboarding/router';
import RsLinkIcon from '@/shared/components/link/link-icon.vue';
import RsOnboardingSocialLogin from '@/modules/onboarding/components/auth/social-login.vue';
import {Icons} from '@/shared/components/icon/icons';
import RsOnboardingFormForgotPassword from '@/modules/onboarding/components/forms/forgot-password.vue';
import RsButton from '@/shared/components/buttons/button.vue';

@Component({
  metaInfo: {
    title: 'Login'
  },
  components: {
    RsButton,
    RsOnboardingFormForgotPassword,
    RsOnboardingSocialLogin, RsLinkIcon, RsIcon, RsOnboardingHeader}
})
export default class OnboardingLogin extends Vue {
  OnboardingRouter = OnboardingRouter;
  Icons = Icons;
  env = process.env;

  success = false;

  mounted(){
    this.$track.sendEvent('ForgotPasswordPage')
  }
}
