




































import Component from 'vue-class-component';
import Vue from 'vue';
import { email, required } from 'vuelidate/lib/validators';
import RsInput from '@/shared/components/fields/input.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import { AUTH_SERVICE } from '@/modules/onboarding/services/auth.service';

interface FormForgotPassword {
  email: string;
}

@Component({
  name: 'rs-onboarding-form-forgot-password',
  components: { RsButton, RsInput },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
})
export default class OnboardingFormForgotPassword extends Vue {
  form: FormForgotPassword = {
    email: '',
  };

  sending = false;
  error = '';

  submit() {
    this.error = '';
    this.sending = true;
    this.$track.sendEvent('ForgotPassword', { email: this.form.email });
    AUTH_SERVICE.changePassword(this.form.email)
      .then(() => {
        this.$emit('success');
      })
      .catch((err) => {
        this.error = err.message;
      })
      .finally(() => {
        this.sending = false;
      });
  }
}
